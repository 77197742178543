

































































































































































import { Vue, Component } from 'vue-property-decorator';
import * as XLSX from 'xlsx';
import { mdiTrashCan } from '@mdi/js';
import FileEditorComponent from '../components/FileEditorComponent.vue';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import type { DataTableHeader } from 'vuetify';
import { Ripple } from 'vuetify/lib/directives';
import {
  supportedLocalizations,
  DocsFile,
  LocalizedDocument
} from '../api/document';
import { FileTypes } from '../typings/field';
import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { InvokeCommandOutput, Lambda } from '@aws-sdk/client-lambda';
import { userStore } from '../plugins/store';

interface TableItem {
  id: string;
  field: string;
  column: true;
  import: true;
  overwrite: true;
}

interface UploadInput {
  importLocalizations: string;
  sheet: string;
  hasHeader: boolean;
  columnMapping: ColumnMapping;
  createNewKeys: boolean;
  username: string;
}

interface UploadResult {
  success: string[];
  failure: string[];
}

interface Column {
  column?: string;
  import: boolean;
  overwrite?: boolean;
}

interface ColumnMapping extends Partial<LocalizedDocument<Column>> {
  stringIdentifier: Column;
}

@Component({
  directives: { Ripple },
  components: {
    FileEditorComponent
  }
})
export default class BulkImportView extends Vue {
  private readonly svgPath: string = mdiTrashCan;
  private readonly FileTypes: typeof FileTypes = FileTypes;
  private file: DocsFile | null = null;
  private rawFile: ArrayBuffer | null = null;
  private workbook: XLSX.WorkBook | null = null;
  private sheet: string | null = null;
  private hasError: boolean = false;
  private hasHeader: boolean = true;
  private createNewKeys: boolean = false;
  private result: string = '';
  private importColumns: Record<string, boolean> = {};
  private overwriteColumns: Record<string, boolean> = {};
  private columnMapping: Record<string, string> = {};
  private showSpinner: boolean = false;

  private setHasError(): void {
    this.hasError = true;
    setTimeout((): void => {
      this.hasError = false;
    }, 5000);
  }

  private get showUpload(): boolean {
    return (
      Object.values(this.importColumns).includes(true) &&
      !!this.columnMapping.stringIdentifier &&
      (!this.hasHeader ||
        this.fileHeaders.includes(this.columnMapping.stringIdentifier))
    );
  }

  private get fileHeaders(): string[] {
    return !this.workbook?.Sheets?.[this.sheet ?? ''] || !this.hasHeader
      ? []
      : (XLSX.utils.sheet_to_json(this.workbook.Sheets[this.sheet ?? ''], {
          header: 1
        })[0] as string[]);
  }

  private get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: I18n.get(Translations.IMPORT_LANGUAGE),
        value: 'import',
        align: 'start',
        sortable: false,
        filterable: false
      },
      {
        text: I18n.get(Translations.FIELD),
        value: 'field',
        align: 'start',
        sortable: false,
        filterable: false
      },
      {
        text: I18n.get(Translations.COLUMN),
        value: 'column',
        align: 'start',
        sortable: false,
        filterable: false
      },
      {
        text: I18n.get(Translations.IMPORT_OVERWRITE),
        value: 'overwrite',
        align: 'start',
        sortable: false,
        filterable: false
      }
    ];
  }

  private get tableItems(): TableItem[] {
    return ['stringIdentifier', ...supportedLocalizations].map(
      (row: string): TableItem => ({
        id: row,
        field: I18n.get(Translations[row]),
        column: true,
        import: true,
        overwrite: true
      })
    );
  }

  private setHasHeader(hasHeader: boolean): void {
    if (hasHeader) {
      this.columnMapping = [
        'stringIdentifier',
        ...supportedLocalizations
      ].reduce(
        (
          columnMapping: Record<string, string>,
          key: string
        ): Record<string, string> => ({
          ...columnMapping,
          [key]: key
        }),
        {}
      );
    } else {
      const alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      this.columnMapping = [
        'stringIdentifier',
        ...supportedLocalizations
      ].reduce(
        (
          columnMapping: Record<string, string>,
          key: string,
          index: number
        ): Record<string, string> => ({
          ...columnMapping,
          [key]: alphabet[index]
        }),
        {}
      );
    }
  }

  private async changeFile(importFile: DocsFile | null): Promise<void> {
    if (!importFile || !importFile.url) {
      this.clearXLSX();
    } else {
      this.file = importFile;
      this.rawFile = await fetch(importFile.url).then(
        (response: Response): Promise<ArrayBuffer> => response.arrayBuffer()
      );
      this.loadXLSX();
    }
  }

  private loadXLSX(): void {
    this.workbook = XLSX.read(this.rawFile, {
      type: 'buffer'
    });
    this.sheet = this.workbook.SheetNames[0];
  }

  private async mounted(): Promise<void> {
    this.clearXLSX();
  }

  private clearXLSX(): void {
    if (this.file && this.file.url) {
      URL.revokeObjectURL(this.file.url);
    }
    this.file = null;
    this.rawFile = null;
    this.workbook = null;
    this.sheet = null;
    this.hasHeader = true;
    this.createNewKeys = false;
    this.importColumns = {};
    this.overwriteColumns = {};
    this.columnMapping = ['stringIdentifier', ...supportedLocalizations].reduce(
      (
        columnMapping: Record<string, string>,
        key: string
      ): Record<string, string> => ({
        ...columnMapping,
        [key]: key
      }),
      {}
    );
  }

  private async upload(): Promise<void> {
    if (!this.workbook || !this.sheet) {
      return;
    }
    try {
      this.showSpinner = true;
      const columnMapping: ColumnMapping = [
        'stringIdentifier',
        ...supportedLocalizations
      ].reduce(
        (mapping: ColumnMapping, col: string): ColumnMapping => ({
          ...mapping,
          [col]: {
            column: this.columnMapping[col] || null,
            import: this.importColumns[col] || false,
            overwrite: this.overwriteColumns[col] || false
          }
        }),
        {} as ColumnMapping
      );

      if (
        (await Storage.list('localization/bulk/')).some(
          (item: { key: string }): boolean =>
            item.key === 'localization/bulk/input.xlsx'
        )
      ) {
        throw 'conflict';
      }

      await Storage.put('localization/bulk/input.xlsx', this.rawFile, {
        level: 'public',
        contentType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      const input: UploadInput = {
        importLocalizations: 'public/localization/bulk/input.xlsx',
        sheet: this.sheet,
        hasHeader: this.hasHeader,
        columnMapping,
        createNewKeys: this.createNewKeys,
        username: userStore.email
      };
      const response: InvokeCommandOutput = await new Lambda({
        apiVersion: '2015-03-31',
        region: process.env.REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({
            region: process.env.REGION
          }),
          logins: {
            [`cognito-idp.${process.env.REGION}.amazonaws.com/${process.env.USER_POOL_ID}`]: (
              await Auth.currentSession()
            )
              .getIdToken()
              .getJwtToken()
          },
          identityPoolId: process.env.IDENTITY_POOL_ID as string
        })
      }).invoke({
        FunctionName: process.env.BULK_LAMBDA as string,
        Payload: new TextEncoder().encode(JSON.stringify(input))
      });
      const output: UploadResult = JSON.parse(
        new TextDecoder('utf-8').decode(response.Payload) || '{}'
      );
      if (response.FunctionError) {
        throw output;
      }
      this.result = `${I18n.get(Translations.IMPORT_DONE)}\n\n`;
      if (output.failure?.length) {
        this.result += `${I18n.get(
          Translations.IMPORT_ERROR
        )}\n${output.failure.join('\n')}\n\n`;
      }
      if (output.success?.length) {
        this.result += `${I18n.get(
          Translations.IMPORT_OK
        )}\n${output.success.join('\n')}\n\n`;
      }
      if (!output.failure?.length && !output.success?.length) {
        this.result += `${I18n.get(Translations.IMPORT_SKIP)}\n\n`;
      }
    } catch (err) {
      this.setHasError();
      this.$logger.error(err);
    }
    this.showSpinner = false;
  }
}
